/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  height: 40px;
  padding: 8px 12px;
  width: 100%;
  background-color: white;
  border: 1px solid var(--gray-lightest-color);
  border-radius: 5px;

  box-shadow: none;
}

.StripeElement--focus {
  border-color: var(--primary-light-color);
}

.StripeElement--invalid {
  border-color: #d14343;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
