.react-datepicker-wrapper {
  width: 100%;
  font-size: 16px !important;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  color: #000;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-lightest-color);
  background-color: #fff;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none;
  line-height: 20px;
  letter-spacing: -0.05px;
  text-decoration: none;
  transition: 0.5s ease all;
}

.react-datepicker__input-container input::placeholder {
  color: var(--black-lighter-color) !important;
}

.react-datepicker__input-container input:focus {
  z-index: 2;
  box-shadow: none !important;
  border-color: var(--primary-light-color);
  /* box-shadow: 0 0 0 2px #d6e0ff; */
}

.react-datepicker {
  font-size: 12px;
  color: #000;
  background-color: #fff;
  font-family: 'ProximaNova', 'SF UI Text', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 14px !important;
}

li.react-datepicker__time-list-item {
  height: unset !important;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

@media (max-width: 415px) {
  .react-datepicker__time-container {
    width: 55px !important;
  }

  .react-datepicker__time .react-datepicker__time-box {
    width: 50px !important;
  }

  .react-datepicker__time-list {
    width: 50px !important;
  }

  .react-datepicker__month-container {
    width: 190px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 1.5rem !important;
    margin: 0.166rem 0.066rem !important;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 57px !important;
  }
}
